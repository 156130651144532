<template>
  <div class="wrap-datetime-select">
    <div v-if="title" class="title">{{ title }}</div>
    <section>
      <!-- <div v-if="!range" class="overlay" @click="showDatePicker">{{ overlayDateRange }}</div> -->
      <date-picker
        ref="datePicker"
        v-model="timeRange"
        prefix-class="xmx"
        type="datetime"
        :range="range"
        :lang="lang"
        :placeholder="$t(placeholder)"
        :show-time-panel="showTimeRangePanel"
        :disabled-date="disabledDate"
        :shortcuts="shortcutList"
        class="datetime-select"
        :class="{ hide: !range }"
        :format="format"
        :clearable="range"
        @open="onOpen"
        @close="onClose"
        @pick="onPick"
      >
        <template v-if="!range" #input="picker" @click="showDatePicker">
          <div class="overlay">
            <img src="@/assets/icons/clock-solid.svg" alt="">
            <span>{{ !range && openedTime ? openedTime : picker.props.value }}</span>
          </div>
        </template>
        <i v-if="!range" slot="icon-calendar"></i>
        <i v-if="!range" slot="icon-clean"></i>

        <template v-slot:footer>
          <div class="dt-footer">
            <!-- <span v-if="timeRange[0]">{{ timeRange[0] ? timeRange[0].toLocaleString() : '' }} ~ {{ timeRange[1] ? timeRange[1].toLocaleString() : '' }}</span> -->
            <div class="btn set" @click="toggleTimeRangePanel">{{ showTimeRangePanel ? $t('search_select_date') : $t('search_select_time') }}</div>
            <!-- <div class="btn cancel" @click="hangleDatePanel">{{ $t('close') }}</div> -->
            <button v-if="!range" type="button" class="xmx-btn xmx-datepicker-btn-confirm" @click.stop="onConfirm">{{ $t('ok') }}</button>
          </div>
        </template>
      </date-picker>
    </section>
  </div>

</template>

<script>
  import DatePicker from 'vue2-datepicker'
  import 'vue2-datepicker/locale/zh-tw'
  import 'vue2-datepicker/locale/en'
  import { mapGetters, mapState } from 'vuex'
  import moment from 'moment'
  import { getLang } from '@/i18n/i18n'
  
  export default {
    name: 'DateRangeSelect',
    components: { DatePicker },
    // props: [
    //   'value',
    //   'range', 
    //   'title',
    //   'maxRangeDays',
    //   'enabledDateStart', 
    //   'enabledDateEnd',
    //   'interval',
    //   'shortcuts',
    // ],
    props: {
      value: {
        default: null
      },
      range: {
        type: Boolean,
        default: true
      },
      title: {
        type: String,
        default: ''
      },
      maxRangeDays: {
        type: Number,
        default: 180 // 最大可選擇區間天數
      },
      enabledDateStart: {
        default: null
      },
      enabledDateEnd: {
        default: null
      },
      interval: {
        type: Number,
        default: 30
      },
      shortcuts: {
        type: Array,
      },
      format: {
        type: String,
        default: "YYYY-MM-DD HH:mm"
      },
      placeholder: {
        type: String,
        default: 'search_time_placehold'
      }
    },
    data() {
      return {
        nPick: 0,
        enabledStart: null,
        enabledEnd: null,
        showDatePanel: false,
        showTimeRangePanel: false,
        timeList: {start: '00:00', step:'00:30' , end: '23:30', format: 'HH:mm' },
        defaultShortcuts: [
          { text: this.$t('today'), onClick: () => {
              // 今天 00:00 ~ 23:59
              const date = new Date()
              const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate())
              const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
              return [ startOfDay, endOfDay ]
            }
          },
          { text: this.$t('yesterday'), 
            onClick: () => {
              // 昨天 00:00 ~ 昨天 23:59
              const date = new Date()
              const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)
              const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1, 23, 59, 59)
              return [ startOfDay, endOfDay ]
            }
          },
          { text: this.$t('last_week'), 
            onClick: () => {
              // 7天前 00:00 ~ 今天 23:59
              const date = new Date()
              const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 6)
              const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
              return [ startOfDay, endOfDay ]
            }
          },
          { text: this.$t('last_month'), 
            onClick: () => {
              // 30天前 00:00 ~ 今天 23:59
              const date = new Date()
              const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 29)
              const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
              return [ startOfDay, endOfDay ]
          }},
          { text: this.$t('half_year_ago'), 
            onClick: () => {
              // 過去半年 00:00 ~ 今天 23:59
              const date = new Date()
              const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 179)
              const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
              return [ startOfDay, endOfDay ]
          }}
        ],
        openedTime: null
      };
    },
    computed: {
      ...mapState(['userFlatObj']),
      ...mapGetters(['timezone']),
      lang() {
        const navLang = this.userFlatObj.language ? this.userFlatObj.language : navigator.language
        const lang = { ...getLang(navLang), formatLocale: { firstDayOfWeek: 1 } }
        return lang
      },
      timeRange: {
        get() {
          let val
          if (this.range) {
            val = this.value.map(item => {
              return item ? new Date(item) : null
            })
          } else {
            val = this.value ? new Date(this.value) : null
          }
          return val
        },
        set(times) {
          if (this.range) {
            if (times.length === 2 && !times[0] && !times[1]) times = []
          } 
          this.$emit('input', times)
        }
      },
      overlayDateRange() {
        if (this.range || !this.value) return ''
        const dt = moment(this.value).tz(this.timezone)
        // this.value add 30 minutes
        const dt2 = moment(this.value).tz(this.timezone).add(this.interval, 'minutes')
        return `${dt.format('YYYY-MM-DD HH:mm')} ~ ${dt2.format('YYYY-MM-DD HH:mm')}`
      },
      shortcutList() {
        if (!this.range) return []
        if (this.shortcuts) return this.shortcuts
        return this.defaultShortcuts
      },
    },
    methods: {
      disabledDate(date) {
        if (!this.enabledStart || !this.enabledEnd) return false
        const dt = moment(date).tz(this.timezone)
        return dt.isBefore(this.enabledStart) || dt.isAfter(this.enabledEnd)
      },
      toggleTimeRangePanel() {
        this.showTimeRangePanel = !this.showTimeRangePanel;
      },
      hangleDatePanel() {
        this.showTimeRangePanel = false
        this.showDatePanel = !this.showDatePanel
      },
      showDatePicker() {
        this.$refs.datePicker.focus()
      },
      onOpen() {
        this.nPick = 0
        this.enabledStart = this.enabledDateStart
        this.enabledEnd = this.enabledDateEnd

        if (!this.range) {
          // 1. 暫存 開啟日曆當下時間
          this.openedTime = moment(this.value).format(this.format)
          // console.log(`[DP.onClose] openedTime:`, this.openedTime)
          this.$emit('showDatePanel', true)
        }
      },
      onClose() {
        this.showDatePanel = false
        this.showTimeRangePanel = false

        // console.log(`[DP.onClose]`)
        if (!this.range) {
          if (this.openedTime) {
            // 2. 寫回 開啟日曆時間
            this.timeRange = new Date(this.openedTime)
            // console.log(`[DP.onClose] timeRange:`, this.timeRange)
            this.openedTime = null
          }
          this.$emit('showDatePanel', false)
        }
      },
      onPick(date) {
        if (!this.range) return

        this.nPick++
        if (this.nPick % 2 === 0) {
          // 設定完區間後，預設區間改為props進來的enabledDateStart, enabledDateEnd
          this.enabledStart = this.enabledDateStart
          this.enabledEnd = this.enabledDateEnd
        } else {
          // 選定開始時間後，預設區間改為開始時間前後maxRangeDays天
          this.enabledStart = moment(date).add(-this.maxRangeDays, 'days').toDate()
          this.enabledEnd = moment(date).add(this.maxRangeDays, 'days').toDate()
        }
      },
      onConfirm(/*evt*/) {
        // confirm 完, 會執行 close
        // 3. 寫入 日曆選擇時間
        // console.log(`[DP.onConfirm] value:`, this.value)
        this.$emit('confirm', this.value)
        this.openedTime = null
        this.$refs.datePicker.closePopup()
      }
    },
    // watch: {
    //   openedTime(nVal) {
    //     console.log(`[DP.W.openedTime] nVal:`, nVal)
    //     console.log(`[DP.W.openedTime] value:`, this.value)
    //     console.log(`[DP.W.openedTime] timeRange:`, this.timeRange)
    //   }
    // }
  };
</script>

<style lang="scss" scoped>
.title {
  font-size: px2rem(18);
  font-weight: 300;
  line-height: 28px;
  margin-bottom: 4px;
  color: #FFFFFF;
}

section {
  position: relative;
}

.overlay {
  // position: absolute;
  // top: 0;
  // left: 0;
  margin-left: 0.5rem;
  padding: 0 0.5rem 0;
  color: #0E1924;
  // width: calc(100% - 38px);
  // height: 48px;
  border-radius: 8px;
  font-size: px2rem(20);
  font-weight: 400;
  padding-left: 10px;
  // line-height: 46px;
  // z-index: 2;
  background-color: $color_6E7D93;

  img {
    margin-right: 0.5rem;
    margin-bottom: 0.3rem;
    vertical-align: middle;
  }
}

.datetime-select {
  width: 100%;
  letter-spacing: 0px;
  color: #FFFFFF;
}

::v-deep .mx-datepicker.mx-datepicker-range {
  width: 100%;
  position: relative;
}
::v-deep .mx-input-wrapper {
  width: 100% !important;
  height: 48px;
  font-size: px2rem(20);
}
::v-deep .mx-input-wrapper .mx-input {
  width: 100% !important;
  height: 48px;
}
::v-deep .mx-input {
  width: 100% !important;
  height: 48px;
  border-radius: 8px;
  outline: none;
  border: none;
  font-size: px2rem(20);
  font-weight: 300;
  color: #0E1924;
}

::v-deep .hide .xmx-input {
  color:#FFFFFF;
}

.dt-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 12px;
  padding: 6px 10px;
  .btn {
    padding: 6px 24px;
    font-size: px2rem(18);
    cursor: pointer;
    &.set {
      font-size: px2rem(16);
      color: #E6E6E6;
      border: 1px solid #E6E6E6;
      border-radius: 8px;

      &:hover {
        background-color: $color_FFF_10;
      }
    }
    &.cancel {
      color: #FFFFFF;
      background: #4A5C78;
      border-radius: 8px;
      border: 1px solid #4A5C78;

      &:hover {
        background-color: #6E7D93;
      }
    }
  }
}

</style>